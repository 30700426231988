const axios = require("axios").default;
let apiUrl = null;
if (process.env.VUE_APP_ENV === "dev") {
  apiUrl = "http://localhost";
} else if (process.env.VUE_APP_ENV === "sandbox") {
  apiUrl = "https://sandbox.masspredict.io";
} else {
  apiUrl = "https://app.masspredict.io";
}

module.exports = {
  startSession: async (sessionId, participant_id, urlParams) => {
    let url = new URL(`${apiUrl}/api/v1/bot/${sessionId}`);
    let params = new URLSearchParams(urlParams);
    if (participant_id) params.set("started_participant_id", participant_id);
    url.search = params.toString();
    return axios.get(url.toString());
  },
  dispatchToBot: async (author, message, header) => {
    // eslint-disable-next-line
    return axios.post(`${apiUrl}/api/v1/bot/${author}`, message, {headers: header});
  },
  apiUrl: apiUrl,
};
