import Vue from "vue";
import VueRouter from "vue-router";
import Study from "../views/Study.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/:id",
    name: "Study",
    component: Study,
    props: (route) => ({ rid: route.query.rid }),
  },
  {
    path: "/chat/:id",
    name: "Chat",
    // route level code-splitting
    // this generates a separate chunk (chat.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "chat" */ "../views/Chat.vue"),
    props: (route) => ({ rid: route.query.rid }),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
