import Vue from "vue";
import colors from "vuetify/lib/util/colors";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: "mdi",
  },
  theme: {
    dark: true,
    options: {
      customProperties: true,
    },
    themes: {
      dark: {
        primary: colors.shades.black,
        secondary: colors.shades.white,
        accent: "#1e1e1e",
        error: colors.red.accent3,
        lightgray: "#2a2a2a",
        sidenav: "#181818",
        btncolor: "#303931",
      },
    },
  },
});
