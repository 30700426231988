import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";
import i18n from "./i18n";

Vue.config.productionTip = false;

Sentry.init({
  Vue,
  dsn: "https://cdc9fdb8dd394d52bb01c468052b4820@o485109.ingest.sentry.io/5539753",
  // eslint-disable-next-line no-unused-vars
  beforeSend(event, hint) {
    if (event.exception) {
      Sentry.showReportDialog({
        eventId: event.event_id,
        subtitle2:
          "If you want to be anonymous simply leave the name and email fields as they are.",
        user: {
          name: "anonymous",
          email: "anonymous@anonymous.com",
        },
      });
    }
    return event;
  },
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ["study.masspredict.io", /^\//],
    }),
  ],
  environment: "study-link",
  tracesSampleRate: 0.2,
});

new Vue({
  i18n,
  router,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
