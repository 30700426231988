<template>
  <v-container
    fluid
    style="padding-top: 0px; padding-bottom: 0px; height: 100vh"
    :style="`background: ${this.theme === 'dark' ? 'black' : 'white'};`"
  >
    <v-row dense>
      <v-dialog
        v-model="dialog"
        persistent
        max-width="400"
        :light="theme != 'dark'"
      >
        <v-card>
          <v-alert
            :value="alert"
            color="red"
            border="bottom"
            icon="mdi-home"
            transition="scale-transition"
          >
            We're sorry, but in order to take part in the study you have to
            agree to the privacy policy.
          </v-alert>
          <v-col
            class="text-right"
            style="display: flex; flex-direction: row-reverse"
          >
            <div v-for="(key, val) in languages" :key="key">
              <v-btn
                style="background-color: transparent"
                elevation="0"
                @click="toggle_lang(val)"
              >
                <v-img
                  max-width="25px"
                  contain
                  :src="require(`../assets/images/${val}.png`)"
                ></v-img>
              </v-btn>
            </div>
          </v-col>
          <v-card-title class="headline">{{
            hasScreeningText()
              ? $t("chat.please_read")
              : $t("chat.privacy_header")
          }}</v-card-title>
          <v-card-text v-show="hasScreeningText()" style="font-weight: bold">{{
            screening_text
          }}</v-card-text>
          <v-card-text>{{ $t("chat.privacy_first_line") }}</v-card-text>
          <v-card-text>{{ $t("chat.privacy_second_line") }}</v-card-text>
          <v-card-text>{{ $t("chat.privacy_third_line") }}</v-card-text>
          <v-card-text v-if="collect_email">
            {{ $t("chat.privacy_collect_email") }}
          </v-card-text>
          <v-card-text v-if="collect_gender">
            {{ $t("chat.privacy_collect_gender") }}
          </v-card-text>
          <v-card-text>{{ $t("chat.privacy_fourth_line") }}</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="black--text"
              text
              outlined
              class="mb-2"
              @click="routeToSession"
            >
              {{ $t("chat.privacy_ok_button") }}
            </v-btn>
            <v-btn
              color="black--text"
              text
              outlined
              class="mb-2"
              @click="declineTos"
            >
              {{ $t("chat.privacy_cancel_button") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
import { available_languages } from "../i18n";
import axios from "axios";
import api from "@/api";

export default {
  name: "Study",
  props: {
    rid: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    dialog: true,
    alert: false,
    sessionId: "",
    languages: available_languages,
    theme: "dark",
    collect_email: false,
    collect_gender: false,
    chat_style: {},
    disqualified_should_redirect: false,
    disqualified_redirect_url: "",
    screening_text: "",
  }),

  methods: {
    hasScreeningText() {
      return (
        this.disqualified_should_redirect &&
        this.disqualified_redirect_url &&
        this.disqualified_redirect_url.trim().length &&
        this.screening_text &&
        this.screening_text.trim().length
      );
    },
    acceptTos() {
      this.dialog = false;
    },
    declineTos() {
      if (this.hasScreeningText()) {
        window.location.href = `${this.disqualified_redirect_url}${window.location.search}`;
      } else {
        this.alert = true;
      }
    },
    toggle_lang(lang) {
      this.$i18n.locale = lang;
    },
    routeToSession() {
      window.location.href = `${window.location.origin}/chat/${this.$route.params.id}${window.location.search}`;
    },
  },
  async created() {
    axios
      .get(`${api.apiUrl}/api/v1/sessions/info/${this.$route.params.id}`)
      .then(
        function (response) {
          this.$i18n.locale = response.data.display_lang;
          this.collect_email = response.data.collect_email;
          this.chat_style = response.data.chat_style;
          this.disqualified_should_redirect =
            response.data.disqualified_should_redirect;
          this.disqualified_redirect_url =
            response.data.disqualified_redirect_url;
          this.screening_text = response.data.screening_text;
          if (this.chat_style) {
            this.theme = this.chat_style["chat-theme"];
          }
        }.bind(this)
      );
  },
};
</script>

<style>
a {
  text-decoration: none;
}

a:link {
  color: white;
}

/* visited link */
a:visited {
  color: white;
}

/* mouse over link */
a:hover {
  color: lightgrey;
}

/* selected link */
a:active {
  color: white;
}

.theme--dark.v-card {
  background-color: #2a2a2a;
}

.v-card {
  display: flex !important;
  flex-direction: column;
}

.v-card__text {
  flex-grow: 1;
  overflow: auto;
}
</style>
