<template>
  <div id="app">
    <v-app>
      <v-container fluid class="black fill-height">
        <v-row>
          <router-view />
        </v-row>
      </v-container>
    </v-app>
  </div>
</template>

<script>
export default {
  name: "App",

  data: () => ({
    //
  }),
};
</script>

<style>
#app {
  font-family: sans-serif;
  overscroll-behavior-y: none;
  top: 0;
  left: 0;
}
.v-application__wrap {
  min-width: none;
}
#inverted-video {
  transform: rotateY(180deg) !important;
  -webkit-transform: rotateY(180deg); /* Safari and Chrome */
  -moz-transform: rotateY(180deg); /* Firefox */
}
.v-dialog--fullscreen {
  overflow-y: hidden !important;
}
/* width */
::-webkit-scrollbar {
  width: 15px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #202020;
  border-left: 1px solid #2c2c2c;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #3e3e3e;
  border: solid 3px #202020;
  border-radius: 7px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(255, 255, 255, 0.63);
}
.v-card__text,
.v-card__title {
  word-break: normal !important;
}
.v-application--wrap {
  height: 100%;
}
</style>
